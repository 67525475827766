<template>
  <div>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Proyek *</label>
          <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Lokasi *</label>
          <vs-input class="w-full" v-model="data.lokasi"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Mulai *</label>
          <flat-pickr :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}" class="w-full" v-model="data.tgl_mulai"></flat-pickr>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Selesai *</label>
          <flat-pickr :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}" class="w-full" v-model="data.tgl_akhir"></flat-pickr>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Foto Before * <span class="text-xs opacity-75" v-if="isCompressingFotoBefore">(compressing...)</span></label>
          <vs-input class="w-full" type="file" accept="image/*" multiple v-model="data.fotoBeforeTemp" @change="onSelectedFotoBefore"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Foto After * <span class="text-xs opacity-75" v-if="isCompressingFotoAfter">(compressing...)</span></label>
          <vs-input class="w-full" type="file" accept="image/*" multiple v-model="data.fotoAfterTemp" @change="onSelectedFotoAfter"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/1 w-full">
          <label class="ml-1 text-xs">Keterangan</label>
          <vs-textarea class="w-full" v-model="data.keterangan"/>
        </div>
      </div>

      <vs-divider> Detail </vs-divider>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <div class="flex flex-wrap justify-between items-center mb-1">
            <div class="mb-4 md:mb-0 mr-4">
              <div class="flex">
                <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="data.details.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vs-table :data="data.details" stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap">Item Kebersihan</vs-th>
          <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td>
              <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
            </vs-td>
            <vs-td>
              <div @click="chooseItemKebersihan(item.uuid)">
                <vx-input-group class="w-full">
                  <vs-input placeholder="Item Kebersihaan" :value="item.nama_item_kebersihan" readonly/>
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                    </div>
                  </template>
                </vx-input-group>
              </div>
            </vs-td>
            <vs-td><vs-input class="w-full" type="text" v-model="item.keterangan"/></vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <div class="vx-row mt-base">
        <div class="vx-col w-full sm:w-12/12">
          <div class="flex items-center justify-end">
            <vs-button class="mr-3" type="border" :to="{ name: 'estate.kebersihan' }">Batal</vs-button>
            <vs-button :disabled="isLoading || isCompressingFotoAfter || isCompressingFotoBefore" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <!--modal item kebersihan-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data Item Kebersihan"
              :active="modalItemKebersihan.active"
              v-on:update:active="modalItemKebersihan.active = $event">
      <ItemKebersihan :selectable="true" @selected="onSelectedModalItemKebersihan"/>
    </vs-popup>
  </div>
</template>

<script>
import KebersihanRepository from '@/repositories/estate/kebersihan-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import ItemKebersihan from '@/views/pages/master/item-kebersihan/ItemKebersihan'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'KebersihanAdd',
  components: {
    ValidationErrors,
    flatPickr,
    ItemKebersihan,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      isLoading: false,
      isCompressingFotoBefore: false,
      isCompressingFotoAfter: false,
      errors: null,
      proyeks: [],
      modalItemKebersihan: {
        rowUuid: null,
        active: false
      },
      data: {
        id_proyek: null,
        details: []
      }
    }
  },
  methods: {
    async onSelectedFotoBefore (event) {
      const files = event.target.files
      const items = []
      this.isCompressingFotoBefore = true
      for (const file of files) {
        const compressed = await compressImage(file)
        items.push(compressed)
      }
      this.isCompressingFotoBefore = false
      this.data.foto_before = items
    },

    async onSelectedFotoAfter (event) {
      const files = event.target.files
      const items = []
      this.isCompressingFotoAfter = true
      for (const file of files) {
        const compressed = await compressImage(file)
        items.push(compressed)
      }
      this.isCompressingFotoAfter = false
      this.data.foto_after = items
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData(this.data)
      KebersihanRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    chooseItemKebersihan (rowUuid) {
      this.modalItemKebersihan.rowUuid = rowUuid
      this.modalItemKebersihan.active = true
    },

    onSelectedModalItemKebersihan (data) {
      // validate for same item
      if (_.find(this.data.details, item => item.id_item_kebersihan === data.id) !== undefined) {
        return this.notifyWarning('Item yang sama tidak dapat ditambahkan lagi.')
      }

      const uuid = this.modalItemKebersihan.rowUuid
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details[index].id_item_kebersihan = data.id
      this.data.details[index].nama_item_kebersihan = data.nama
      this.modalItemKebersihan.active = false
    },

    addRow () {
      const row = { uuid: uuid() }
      this.data.details.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details.splice(index, 1)
    },

    clearRows () {
      this.$vs.dialog({
        ...this.confirmOptionsClearRows(),
        accept: () => {
          this.data.details = []
        }
      })
    },

    confirmOptionsClearRows () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Item RAB akan dihapus semua?',
        acceptText: 'Ya',
        cancelText: 'Batal'
      }
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'estate.kebersihan' })
    }
  }
}
</script>
